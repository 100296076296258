import {colors} from "@c10h/colors";
import {NavCircle} from "@components/Nav/Nav";
import NextImage from "@components/NextImage";
import NextLink from "@components/NextLink";
import React, {useState} from "react";
import {DropDownConfig, DropMenuItem} from "src/constants/NavConstants";
import {transparent} from "src/utils/transparent";
interface NavItemProps extends DropMenuItem {
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<string>>;
  onClick: (value: string) => void;
}
export const NavItem: React.FC<NavItemProps> = ({
  setIsHovered,
  isHovered,
  href,
  image,
  title,
  subTitle,
  as,
  external,
  onClick,
}) => (
  <li
    className="br8 t-100"
    onFocus={() => setIsHovered(title)}
    // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<string>'.
    onBlur={() => setIsHovered(null)}
    onMouseEnter={() => setIsHovered(title)}
    // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<string>'.
    onMouseLeave={() => setIsHovered(null)}
    style={{borderRadius: "6rem", backgroundColor: isHovered ? colors.lightYellow : "white"}}
  >
    <NextLink as={as} href={href} external={external}>
      <a
        {...(external && typeof href !== "object" && {href})}
        className="df aic focus-bsDarkBlue3 p2 br8"
        onClick={() => onClick(title)}
        data-cy="menu-child"
      >
        <div
          className="br50 ovf-x-h ovf-y-h bg-white df aic jcc mr3"
          style={{width: 58, height: 58}}
        >
          <NextImage quality={100} extension="png" width={50} height={50} src={image} alt="" />
        </div>
        <div className="df fdc pr6">
          <strong className="gray800 font-isb fs18 lh15 wsnw">{title}</strong>
          <span className="gray800 font-ir fs14 lh15 wsnw">{subTitle}</span>
        </div>
      </a>
    </NextLink>
  </li>
);

interface Props {
  item: DropDownConfig;
  // @ts-expect-error TS7010: 'onFocus', which lacks return-type annotation, implicitly has an 'any' return type.
  onFocus();
  onClick: (value: string) => void;
}

export const DropdownMenuItem: React.FC<Props> = ({item, onFocus, onClick}) => {
  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'string | (() => string)'.
  const [isHovered, setIsHovered] = useState<string>(null);
  const {id, title, subTitle, secondaryTitle, moreMenuItems, style = {}} = item;

  // 1px is to account for border width
  // Not 100% because; keep dropdown menu closer to parent buttons so that it doesn't overlap with other buttons
  // and closes when user moves mouse away from parent button
  const placementStyle = {top: "80%", ...style};

  return (
    <div
      role="region"
      className="pos-a wa brdt brd-gray100 bs1 hfc contrast-tb"
      style={placementStyle}
      onFocus={onFocus}
      id={`${id}-dropdown`}
    >
      <div className="df bg-white">
        {item.customHtml || (
          <>
            <section aria-labelledby={`${id}-main`} className="ph12 pt10 pb12">
              <h3
                className={`ml4 mb4 ttu fs13 lh24 font-im gray400 ls3 ${
                  subTitle ? "" : "visually-hidden"
                }`}
                id={`${id}-main`}
              >
                {subTitle || title}
              </h3>
              <ul className="dg gtc2a gafr cg3 cr3">
                {item.menuItems?.map(item => (
                  <NavItem
                    key={item.title}
                    setIsHovered={setIsHovered}
                    isHovered={item.title === isHovered}
                    onClick={onClick}
                    {...item}
                  />
                ))}
              </ul>
            </section>
            {moreMenuItems && (
              <section
                aria-labelledby={`${id}-secondary`}
                className="ph12 pt10 pb12 minw22 contrast-tbl"
                style={{backgroundColor: transparent(colors.gray100, 50)}}
              >
                <h3 id={`${id}-secondary`} className="ml4 mb8 ttu fs13 lh24 font-im gray400 ls3">
                  {secondaryTitle}
                </h3>
                <ul>
                  {moreMenuItems.map(({title, as, href, external, newTab}) => (
                    <li
                      className="mb3 df"
                      key={title}
                      onFocus={() => setIsHovered(title)}
                      // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<string>'.
                      onBlur={() => setIsHovered(null)}
                      onMouseEnter={() => setIsHovered(title)}
                      // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<string>'.
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      <NavCircle className="w4 jcs" isHovered={title === isHovered} />
                      <NextLink {...{as, href, external}} passHref>
                        <a
                          {...(newTab && {target: "_blank", rel: "noopener noreferrer"})}
                          {...(external && {href})}
                          // @ts-expect-error TS2322: Type 'string | false' is not assignable to type 'string | undefined'.
                          href={typeof href !== "object" && href}
                          className="fx wsnw gray800 hover-gray800 font-im hover-font-isb fs16 lh16"
                          onClick={() => onClick(title)}
                          data-cy="menu-child"
                        >
                          {title}
                        </a>
                      </NextLink>
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
};
