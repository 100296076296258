import React, {memo, useCallback} from "react";
import Link from "next/link";
import {v5Pages} from "../../_common/_constants";
import Circle from "@components/Nav/Circle";
import {trackNavLinkClick} from "./utils";

type Props = {
  name: string;
  slug: string;
  count: number;
};

const RegionLink = ({name, slug, count}: Props) => {
  const url = v5Pages.clinicDetails.replace("[slug]", slug);

  const onClick = useCallback(() => trackNavLinkClick("Locations", "Menu", name, name), [name]);

  return (
    <li className="mb-5" data-cy="desktop-menu-item">
      <Link href={url} className="flex items-center gap-2 min-w-[10rem] group" onClick={onClick}>
        <span className="group-hover:opacity-100 opacity-0">
          <Circle />
        </span>
        <span className="relative">
          {/* Hidden Placeholder to prevent items shift when text get bolder on hover */}
          <span className="whitespace-nowrap font-i font-semibold opacity-0" aria-hidden>
            {name}
          </span>
          <span className="whitespace-nowrap font-i hover:font-semibold text-gray-1000 absolute left-0">
            {name}
          </span>
        </span>

        <span className="font-m text-xs	text-gray-800">{count}</span>
      </Link>
    </li>
  );
};

export default memo(RegionLink);
