import React, {useState} from "react";
import {DrawerItem} from "src/constants/NavConstants";
import NextLink from "@components/NextLink";
import Link from "next/link";

const DrawerNavAnchor: React.FC<DrawerItem & {closeDrawer: () => void}> = ({
  title,
  href,
  as,
  newTab,
  external,
  ariaLabel,
  closeDrawer,
  count,
  subSubItems,
}) => {
  const [hovered, setHovered] = useState(false);

  const toggleHovered = (boolean?: boolean) => () => {
    if (typeof boolean === "boolean") {
      setHovered(boolean);
    } else {
      setHovered(!hovered);
    }
  };
  return (
    <li key={title} className="gray800 hover-darkGreen fs16 lh22">
      {subSubItems ? (
        <span className="ml3 font-c text-gray-600">{title}</span>
      ) : (
        <NextLink as={as} href={href} external={external}>
          <a
            aria-label={ariaLabel || title}
            className="focus-bsDarkBlue3 dif aic"
            onFocus={toggleHovered(true)}
            onBlur={toggleHovered(false)}
            {...(newTab && {target: "_blank", rel: "noopener noreferrer"})}
            {...(external && ({href} as {href: string}))}
            data-cy="drawer-child-link"
            onClick={closeDrawer}
          >
            <span className="ml3 font-c text-gray-1000 hover:font-semibold">{title}</span>
            {count && <span className="font-m text-xs	text-gray-800 ml-2">{count}</span>}
          </a>
        </NextLink>
      )}
      {subSubItems?.map(subSubItem => (
        <ul key={subSubItem.title} className="ml-10">
          <li>
            <Link href={subSubItem.href}>
              <span className="text-gray-1000 hover:font-semibold">{subSubItem.title}</span>
              <span className="font-m text-xs	text-gray-800 ml-2">{subSubItem.count}</span>
            </Link>
          </li>
        </ul>
      ))}
    </li>
  );
};

export default DrawerNavAnchor;
