import {useDialog} from "@react-aria/dialog";
import {FocusScope} from "@react-aria/focus";
import {useModal, useOverlay, usePreventScroll} from "@react-aria/overlays";
import {AriaDialogProps} from "@react-types/dialog";
import React from "react";

import {highZIndexMap} from "../../../../utils/zIndexMap";

interface ModalDialogProps extends AriaDialogProps {
  title: string;
  isDismissable: boolean;
  isFullWidth: boolean;
  isTitleHidden: boolean;
  children: React.ReactNode;
  blurBackdrop?: boolean;
  zIndex?: number;
}

const ModalDialog: React.FC<ModalDialogProps> = React.memo(props => {
  const {title, children, isFullWidth, isTitleHidden, blurBackdrop, zIndex} = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = React.useRef<HTMLDivElement>(null);
  const {overlayProps, underlayProps} = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const {modalProps} = useModal();

  // Get props for the dialog and its title
  const {dialogProps, titleProps} = useDialog(props, ref);

  const fullWidthClasses = "inset-0 flex flex-col overflow-y-auto static w-screen";

  return (
    <div
      style={{
        position: "fixed",
        zIndex: zIndex || highZIndexMap.MOBILE_DRAWER,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        backdropFilter: blurBackdrop ? "blur(4px)" : "none",
      }}
      {...underlayProps}
    >
      {/*
      When StrictMode is on, you will see:TypeError: Cannot set properties of undefined (setting 'nodeToRestore')
      This is a known bug: https://github.com/adobe/react-spectrum/issues/779
      */}
      <FocusScope contain restoreFocus autoFocus>
        <div
          className={`aria-focus gray800 ${isFullWidth ? fullWidthClasses : ""}`}
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={ref}
        >
          <h3 className={`${isTitleHidden ? "visually-hidden" : ""}`} {...titleProps}>
            {title}
          </h3>
          {children}
        </div>
      </FocusScope>
    </div>
  );
});

ModalDialog.displayName = "ModalDialog";

export default ModalDialog;
