import {FeatureFlagMap, fetchFeatureFlags} from "@services/featureFlags";
import {useCallback} from "react";

import {QueryState, useQueryController} from "../hooks/useQueryController";

export const useFeatureFlagEvaluation = <T extends FeatureFlagMap>(
  defaultFlags: T,
  skip = false,
): QueryState<T> => {
  const fn = useCallback(() => fetchFeatureFlags(defaultFlags), [defaultFlags]);
  return useQueryController({
    fn,
    skip,
    ifRejectedValue: defaultFlags,
    initialValue: defaultFlags,
    cacheKey: JSON.stringify(defaultFlags),
  });
};
